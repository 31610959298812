import React, { forwardRef, useState, useEffect } from "react";
import {
	Text,
	Box,
	Flex,
	Heading,
	Image,
	VStack,
	List,
	ListItem,
	useColorModeValue,
	useBoolean,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import WaitlistSignup from "./WaitlistSignup";

// Placeholder images, replace with your actual images
import featureImage1 from "../assets/feature1.png";
import featureImage2 from "../assets/feature2.png";
import featureImage3 from "../assets/feature3.png";
import featureImage4 from "../assets/feature4.png";
import featureImage5 from "../assets/feature5.png"; // New feature image
import logo_yellow from "../assets/Sumly-logo-yellow.png";
import CustomLogoIcon from "./CustomIcon";

const FeatureBox = ({
	image,
	headingText,
	children,
	bgColor = "#FFFFFF",
	borderColor = "transparent",
	label,
	labelFontSize: propLabelFontSize,
	imageLabel,
}) => {
	// Correctly handle the default value for labelFontSize
	const labelFontSize = propLabelFontSize || {
		base: "20px",
		sm: "30px",
		md: "38px",
	};

	return (
		<Flex
			position="relative"
			bg={bgColor}
			border="2px solid"
			borderColor={borderColor}
			borderRadius="lg"
			align="top"
			direction={{ base: "column", md: "row" }}
			w="full"
			h={{ base: "auto", sm: "250px", md: "420px", lg: "400px" }}
			px={{ base: "4", md: "8", lg: "20" }}
			py={{ base: "10", md: "5", lg: "10" }}
			justifyContent={"space-between"}
		>
			{imageLabel ? (
				// If imageLabel prop is provided, render this Box with the image
				<Box
					position="absolute"
					top={{ base: "-3%", md: "15px" }}
					left={{ base: "3%", md: "-60px" }}
					bg="transparent" // You might not need a background color for the image
					width={{ base: "50px", sm: "70px", md: "120px" }} // Adjust size as needed
					height={{ base: "50px", sm: "70px", md: "120px" }}
					display="flex"
					alignItems="center"
					justifyContent="center"
					zIndex="1"
				>
					<Image
						src={imageLabel}
						alt="Label"
						objectFit="cover"
						w="full"
						h="full"
					/>
				</Box>
			) : (
				// Otherwise, render the text label as before
				<Box
					position="absolute"
					top={{ base: "-25px", md: "40px", lg: "50px" }}
					left={{ base: "3%", md: "-40px", lg: "-45px" }}
					bg="#B9F5E7"
					borderColor="#265F5C"
					border="2px"
					color="#265F5C"
					borderRadius="full"
					width={{ base: "50px", sm: "70px", md: "80px", lg: "90px" }}
					height={{ base: "50px", sm: "70px", md: "80px", lg: "90px" }}
					display="flex"
					alignItems="center"
					justifyContent="center"
					fontSize={labelFontSize} // Assuming you want to apply the fontSize here as well
					fontWeight="bold"
					zIndex="1"
				>
					{label}
				</Box>
			)}
			<Box
				w={{ base: "100%", md: "50%", lg: "45%" }}
				ml={{ base: "0", md: "4", lg: "8" }}
				textAlign={{ base: "left", md: "left" }}
				pt={{ base: "0", md: "2" }}
			>
				<Box
					height="100px"
					alignContent={"center"}
					width={{ base: "100%", lg: "90%" }}
				>
					<Heading
						as="h3"
						size={{ base: "2xl", md: "xl", lg: "2xl" }}
						mb="4"
						color="#265F5C"
						fontFamily="F37Boost"
						// sx={{ whiteSpace: "pre-line" }} // Add this line
						whiteSpace="pre-line"
					>
						{headingText}
					</Heading>
				</Box>
				<Box py={{ md: "10px", lg: "20px" }}>{children}</Box>
			</Box>

			<Box
				w={{ base: "100%", md: "50%", lg: "45%" }}
				h="auto"
				// mt={{ base: "6", md: "10" }}
				display="flex"
				justifyContent="flex-end"
				align="center"
				mr={{ base: "0", md: "4", lg: "8" }}
				px={{ base: "4", md: "0" }}
			>
				<Image
					src={image}
					alt="Feature"
					objectFit="contain"
					w={{ base: "100%", md: "90%", lg: "80%" }}
					h="auto"
				/>
			</Box>
		</Flex>
	);
};

const FeaturesSection = forwardRef(({ highlightSignup, ...props }, ref) => {
	return (
		<Box
			{...props}
			w="100vw" // Ensure the background is full width
			bg="#265F5C" // Background color for the section
			p={{ base: "4", md: "8" }} // Responsive padding
		>
			<VStack
				spacing={{ base: "10", md: "8" }} // Space between each feature box
				mx="auto" // Center the VStack container horizontally
				w={{ base: "90%", md: "85%", lg: "80%" }} // Max width to match the mission section
			>
				<Heading
					size={{ base: "xl", md: "2xl" }} // Adjust size as needed
					color="white" // Adjust color to fit the design
					textAlign="center" // Center the text
					mb={{ base: "0", md: "8" }}
					mt={{ base: "4", md: "8" }}
					fontWeight="normal"
				>
					<span style={{ color: "#F8F142", fontWeight: "bold" }}>Sumly</span> is
					the{" "}
					<span
						style={{
							fontWeight: "bold",
							color: "#F8F142",
							fontStyle: "italic",
						}}
					>
						easiest
					</span>
					{"  "}
					way to track your personal and business finances.
				</Heading>
				<FeatureBox
					image={featureImage1}
					label="1"
					headingText="We determine how much you overpaid."
				>
					<Text mb="4" color="#265F5C" fontWeight={"500"}>
						Simply connect your bank account(s) and we’ll scan your finances
						from the past year to identify potential missed opportunities for
						tax write-offs.
					</Text>
					<Text mb="4" fontWeight="bold" color="#265F5C">
						We can’t fix last year, but we can make sure you don’t overpay
						again.
					</Text>
				</FeatureBox>

				<FeatureBox
					image={featureImage2}
					label="2"
					headingText="We automatically track expenses for you."
				>
					<Text mb="4" color="#265F5C" fontWeight={"500"}>
						We use machine learning to categorize every expense. We’ll notify
						you if any expenses need to be reviewed or if a receipt is needed.
					</Text>
					<Text mb="4" fontWeight="bold" color="#265F5C">
						Don’t worry! We make this as simple as possible so you can spend
						more time growing your business.
					</Text>
				</FeatureBox>

				<FeatureBox
					image={featureImage3}
					label="3"
					headingText="You watch your savings grow!"
				>
					<Text mb="4" color="#265F5C" fontWeight={"500"}>
						For every tax-deductible expense, we show you how you are reducing your 
						tax burden and maximizing your savings!
					</Text>
					<Text mb="4" color="#265F5C" fontWeight="bold">
						The average self-employed individual overpays by $1,300 (!)
					</Text>
				</FeatureBox>

				<FeatureBox
					image={featureImage4}
					label="4"
					headingText="Got questions? 
					             
					We've got experts."
				>
					<Text mb="4" color="#265F5C" fontWeight={"500"}>
						Should you incorporate? Joint filing and need advice? Need help
						understanding what counts as an expense? We got you.
					</Text>
					<Text mb="4" color="#265F5C" fontWeight="bold">
						We’ve built an AI Taxbot to answer most of your questions and have
						tax experts who can handle the rest!
					</Text>
				</FeatureBox>

				<FeatureBox
					image={featureImage5}
					label="5"
					headingText="With all the money you're saving, you can..."
				>
					<List mt="10px" spacing={3}>
						{[
							"Reinvest in your business",
							"Buy a house",
							"Take a vacation",
							"Save for retirement",
							"Splurge on yourself!",
						].map((item, index) => (
							<ListItem key={index} display="flex" alignItems="center">
								<CustomLogoIcon mr="8" />
								<Text color="#265F5C" ml="8" fontWeight={"500"}>
									{item}
								</Text>
							</ListItem>
						))}
					</List>
				</FeatureBox>

				<Flex
					position="relative"
					bg={"#C5F3E8"}
					border="2px solid"
					borderColor={"#FFF"}
					borderRadius="lg"
					align="center"
					justify="center"
					w="full"
					h={{ base: "auto", sm: "250px", md: "420px", lg: "400px" }}
					px={{ base: "4", md: "10" }}
					py={{ base: "6", md: "10" }}
					direction="column"
					ref={ref}
				>
					<Heading
						size={{ base: "xl", md: "2xl", lg: "4xl" }} // Adjust size as needed
						color="#265F5C" // Adjust color to fit the design
						textAlign="center" // Center the text
						mb={{ base: "4", md: "6", lg: "8" }}
						mt={{ base: "4", md: "6", lg: "8" }}
						fontWeight="normal"
						fontFamily={"F37Boost"}
					>
						GET A{" "}
						<span
							style={{
								background: "#F8F142", // Highlight background color
								padding: "0.2em", // Optional: Adds padding around the highlighted text
								borderRadius: "60px", // Optional: Adds rounded corners to the highlight
								fontWeight: "bold", // Optional: Makes the highlighted text bold
							}}
						>
							FREE 1-ON-1
						</span>
						{"  "}
						CONSULTATION WITH A TAX PRO!
					</Heading>
					<Text color="#265F5C" fontSize={{ base: "20px" }} maxWidth="3xl">
						Limited time offer! The first 100 users will receive a free 
						1-on-1 consultation with a tax professional to review their 
						personalized tax strategy.
					</Text>
					<WaitlistSignup
						width={{ base: "90%", md: "60%" }}
						highlight={highlightSignup}
					/>
				</Flex>
			</VStack>
		</Box>
	);
});

export default FeaturesSection;
