import {
	Box,
	Container,
	Text,
	Stack,
	Link,
	Image,
	Flex,
} from "@chakra-ui/react";
import { Link as RouterLink} from "react-router-dom";
import { React, cloneElement } from "react";
import {
	FaInstagram,
	FaFacebookF,
	FaTwitter,
	FaLinkedinIn,
} from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import logo from "../assets/Sumly-asset-light.png";

const SocialButton = ({ children, label, href }) => {
	return (
		<Link
			href={href}
			isExternal
			aria-label={label}
			p={2}
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				color: "white",
			}}
		>
			{cloneElement(children, {
				color: "white", // This sets the icon color to white
				style: { fontSize: "26px" }, // This adjusts the size of the icon. You can set this value as needed
			})}
		</Link>
	);
};

const Footer = () => {
	return (
		<Flex bg="#265F5C" py={{ base: "2", md: "5" }} w="full" zIndex={1000}>
			<Container maxW={{ base: "90vw", md: "80vw" }}>
				<Stack direction="row" justify="space-between" align="center">
					{/* Replace this Text component with an Image component */}
					<Image src={logo} alt="Sumly Logo" height="30px" />{" "}
					{/* Adjust the path to your logo and the height as needed */}
					<Stack direction="row" spacing={6}>
						<SocialButton
							label="Instagram"
							href="https://www.instagram.com/getsumly/"
						>
							<FaInstagram />
						</SocialButton>
						<SocialButton
							label="LinkedIn"
							href="https://www.linkedin.com/company/sumly"
						>
							<FaLinkedinIn />
						</SocialButton>
					</Stack>
				</Stack>

				<Text fontSize="sm" mt={5} align="left" color={"white"}>
					By using this website, you accept and agree to&nbsp; 
					<RouterLink
						to="/legal-pages/terms-of-use"
						target="_blank"
						style={{
							color:"#f8f142"
						}}
					>
						Sumly's Terms of Use
					</RouterLink>
					&nbsp;and&nbsp;
					<RouterLink 
						to="/legal-pages/privacy-policy" 
						target="_blank"
						style={{
							color:"#f8f142"
						}}
					>
						Privacy Policy
					</RouterLink>.
					&nbsp;
					<RouterLink 
						to="/support" 
						target="_blank"
						style={{
							color:"#f8f142"
						}}
					>
						Contact support
					</RouterLink>.

				</Text>

				<Text fontSize="xs" mt={2} align="left" color={"white"}>
					Copyright @ 2025 Sumly. All rights reserved.
				</Text>

			</Container>
		</Flex>
	);
};

export default Footer;
