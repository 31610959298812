import React, { useEffect } from 'react';
import LandingPage from './components/LandingPage';
import PrivacyPolicy from './components/PrivacyPolicy';  // Import the PrivacyPolicy component
import TermsOfUse from './components/TermsOfUse';        // Import the TermsOfUse component
import Support from "./components/Support";              // Import the Support component
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import Fonts from './fonts.js';
import theme from './theme'

const App = () => {

  const isProduction = process.env.REACT_APP_ENV === 'production';
  const envAccessKey = process.env.REACT_APP_ACCESS_KEY || '';

  useEffect(() => {
    document.title = `${!isProduction ? 'DEV - ' : ''}Sumly - Profitable Self-Employment`;
  }, []);

  let userInput = null;
  let isUserAuth = localStorage.getItem('isUserAuth') === 'true';
  while (!isProduction && isUserAuth === false) {
    userInput = window.prompt('Hello there! Please enter your username to access this page.');
    if (userInput === envAccessKey) {
      localStorage.setItem('isUserAuth', 'true');
      isUserAuth = true;
    } 
  }

  return (

    <ChakraProvider theme={theme}>
      <Fonts />
      <Box minH="100vh" minW="100vw">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} exact />
            <Route path="/legal-pages/privacy-policy" element={<PrivacyPolicy />} exact />
            <Route path="/legal-pages/terms-of-use" element={<TermsOfUse />} exact />
            <Route path="/support" element={<Support />} exact />                    
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ChakraProvider>
  );
}

export default App;